.container {
  display: flex;
  flex-direction: column;

  text-decoration: none;

  width: fit-content;
}

.innerContainer {
  display: flex;
  align-items: center;
}

.logoBig {
  width: 88px;
}

.logoSmall {
  width: 72px;
}

.whiteText {
  color: #fff;
}
