@import '@popsure/dirty-swan/dist/grid';

.container {
  text-decoration: none;
  position: relative;

  @include p-size-tablet {
    margin-top: 16px;
  }

  @include p-size-mobile {
    margin-right: 0px;
    min-width: 100%;
  }
}

.quoteContainer {
  height: 100%;
}
