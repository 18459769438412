@import '@popsure/dirty-swan/dist/grid';

.container {
  box-shadow: 0px 2px 20px rgba(97, 96, 162, 0.07);
  transition: box-shadow 0.3s ease-in;
  &:hover {
    box-shadow: 0px 2px 12px rgba(97, 96, 162, 0.2);
  }

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.textOverflowContainer {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin: 0 auto;
}

.imageContainer {
  max-width: 100%;
}
