@import '~@popsure/dirty-swan/dist/grid';
@import '@popsure/dirty-swan/dist/font-weight';

.container {
  @include p-size-tablet {
    justify-content: flex-start !important;
  }

  @include p-size-tablet {
    gap: 16px;
  }

  @include p-size-desktop {
    gap: 40px;
  }
}

.ratingLink {
  text-decoration: none;
}

.singleReviewBadge {
  gap: 12px;

  @include p-size-tablet {
    &:first-of-type {
      min-width: 140px;
    }
  }

  @include p-size-tablet {
    &:nth-of-type(2) {
      min-width: 120px;
    }
  }
}

.ratingName {
  margin-bottom: 4px;
  font-weight: $p-font-weight-bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.star {
  margin-right: 3px;
}

.trustpilotStar {
  color: #00b67a !important;
}
