.message {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .5px;

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }
}
