@import '@popsure/dirty-swan/dist/grid';

.container {
  flex-direction: column;

  @include p-size-tablet {
    flex-direction: row;
    align-items: center;
  }
}

.reviewRating {
  @include p-size-tablet {
    margin-right: 16px;
  }
}

.reviewCount {
  @include p-size-tablet {
    margin-left: 16px;
  }
}
