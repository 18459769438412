.container {
  display: flex;
  flex-direction: column;

  text-decoration: none;

  width: fit-content;
}

.inner-container {
  display: flex;
  align-items: center;
}

.white-font {
  color: white;
}
