@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    margin-top: 48px;
  }
}

.cardContainer {
  margin-bottom: 48px;

  @include p-size-tablet {
    flex-direction: row-reverse !important;
    align-items: center;
    justify-content: flex-end;
    gap: 56px;
  }
}

.cardContainer:last-of-type {
  margin-bottom: 0;
}

.cardText {
  @include p-size-tablet {
    max-width: 544px;
  }
}

.cardImage {
  @include p-size-tablet {
    min-width: 220px;
    width: 264px;
    margin-top: 0;
  }
}
