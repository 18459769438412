@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.root {
  overflow-x: hidden;
}

.heroSection,
.uspSection,
.differentiationSection,
.recToolCard {
  img {
    height: auto;
  }
}

.uspSection,
.differentiationSection,
.dataSecuritySection {
  @include p-size-tablet {
    margin-top: 96px;
  }
}

.productSection,
.blogSection {
  @include p-size-tablet {
    margin-top: 112px;
  }
}

.heroSection {
  @include p-size-mobile {
    flex-direction: column;

    button {
      width: 100%;
    }

    /* We add an additional 16px of horizontal spacing on wider mobile screens so the button width is balanced with the rest of the layout. */
    @media (min-width: 342px) {
      padding: 56px 16px;
    }
  }

  @include p-size-tablet {
    gap: 24px;
    flex-direction: row;
    padding: 102px 0;
  }

  @include p-size-desktop {
    gap: 160px;
  }

  .heroSectionTextWrapper {
    width: 100%;
    @include p-size-tablet {
      min-width: 40%;
      max-width: 438px;
    }
  }

  .heroSectionImageWrapper {
    max-width: 464px;

    @include p-size-mobile {
      margin-top: 40px;
    }
  }
}

.uspCard {
  @include p-size-tablet {
    flex-direction: row-reverse !important;
    align-items: center;
    gap: 40px;
  }
}

.uspCardText {
  @include p-size-tablet {
    max-width: 488px;
  }
}

.uspCardImage {
  @include p-size-tablet {
    margin-top: 0;
    max-width: 320px;
    min-width: 220px;
  }
}

.productSection {
  .itemHeader {
    margin-top: 40px;
  }

  @include p-size-tablet {
    .itemHeader {
      margin-top: 48px;
    }
  }

  & > div > div:first-child .itemHeader,
  .healthLink {
    margin-top: 32px;

    @include p-size-tablet {
      margin-top: 40px;
    }
  }
}

.productGridItems {
  @include p-size-tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include p-size-mobile {
    grid-template-columns: 1fr;
    grid-template-row: repeat(4, 1fr);
  }

  .verticalItem {
    display: flex;
    gap: 20px;
    padding: 20px;

    @include p-size-mobile {
      padding: 0px;
      background-color: $ds-transparent !important;
    }

    border: 1px solid $ds-transparent;
    transition: 0.6s cubic-bezier(0.21, 0.81, 0.42, 1.03);

    &:hover {
      border-color: $ds-primary-300;
    }
  }

  p {
    margin-top: 4px;
  }
}

.healthLink {
  text-decoration: none;
  color: $ds-grey-700;
  height: max-content;
  padding-top: 4px;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: $ds-primary-500;
  }

  &:focus-visible {
    outline: 2px solid $ds-grey-900;
    border-radius: 2px;
    outline-offset: 2px;
  }

  span {
    margin-left: 6px;
  }
}

.desktopHealthLink {
  @include p-size-mobile {
    display: none;
  }

  @include p-size-tablet {
    display: inline-block;
  }
}

.mobileHealthLink {
  @include p-size-mobile {
    display: inline-block;
  }

  @include p-size-tablet {
    display: none;
  }
}

.categoryIcon {
  width: 16px;
  height: auto;
}

.recToolCard {
  @include p-size-tablet {
    padding: 48px 40px;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
  }

  @include p-size-desktop {
    gap: 96px;
  }
}

.recToolCardText {
  @include p-size-tablet {
    max-width: 55%;
  }

  @include p-size-desktop {
    max-width: 50%;
  }

  @include p-size-mobile {
    a {
      width: 100%;
    }
  }
}

.recToolCardImage {
  @include p-size-tablet {
    min-width: 40%;
  }

  @include p-size-mobile {
    margin: 32px auto 0;

    img {
      height: max-content;
    }
  }
}

.differentiationSection {
  @include p-size-tablet {
    padding-bottom: 48px;
  }
}

.separator {
  border-radius: 8px;
  height: 4px;
  width: 0;
  max-width: 105px;
  background-color: $ds-purple-500;
}

.blogSection {
  @include p-size-tablet {
    padding-top: 48px;
  }
}

.blogButtonDesktop {
  @include p-size-mobile {
    display: none;
  }

  @include p-size-tablet {
    display: block;
  }
}

.blogButtonMobile {
  @include p-size-mobile {
    display: block;
  }

  @include p-size-tablet {
    display: none;
  }
}

.sectionNewsContainer {
  @include p-size-tablet {
    justify-content: space-evenly;
  }
  @include p-size-desktop {
    justify-content: space-between;
  }
  @include p-size-mobile {
    justify-content: center;
  }
  a {
    text-decoration: none;
  }
}

.newsCard {
  height: 356px;
}

.dataSecuritySection {
  @include p-size-tablet {
    gap: 32px;
  }

  @include p-size-desktop {
    padding: 50px 0 0;
  }
}

.dataSecurityColumn {
  @include p-size-tablet {
    max-width: 100%;
    width: 100%;
    align-items: center;
    h3 {
      font-size: 24px;
      line-height: 34px;
    }
  }
  @include p-size-desktop {
    max-width: 288px;
    align-items: flex-start;
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.securityCard {
  @include p-size-tablet {
    flex: 1;
  }
}
