@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/grid';

.container {
  @include p-size-tablet {
    margin-top: 96px;
  }
}

.separator {
  border-radius: 8px;
  height: 4px;
  width: 0;
  max-width: 105px;
  background-color: $ds-primary-500;
}

.review-badge-container {
  padding: 24px;

  background-color: white;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
}

.carousel-container {
  position: relative;
  display: flex;

  overflow: hidden;

  @include p-size-tablet {
    overflow: initial;
    margin: 32px 0;
  }
}

.reviews-container {
  overflow: hidden;
}

.reviews-inner-container {
  display: flex;
  justify-content: space-between;
}

.chevron-left-container,
.chevron-right-container {
  position: absolute;
  top: calc(50% - 16px);
}

.chevron-left-container {
  left: -56px;
}

.chevron-right-container {
  right: -56px;
}

.chevron-button {
  position: relative;
  cursor: pointer;

  border: none;
  background-color: unset;

  &--bottom {
    display: flex;
    justify-content: flex-end;
  }
}

.chevron-inner-container {
  display: flex;
  justify-content: space-evenly;
}
